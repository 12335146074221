
  *{
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    
  }
  th
  {
      vertical-align:top !important;
    
      
}

  table{
    overflow: auto;
    table-layout: auto !important;
  }

  tr:nth-child(even) {background-color: #f2f2f2;}

  
  thead {
    background-color: #6c757d;
    color: white;
  }
    
  .header{
    width: 100%;
    height: 100px;
    display: block;
    background-color: #101010;
  }
  .inner_header{
    width: 92%;
    height: 100%;
    display: block;
    margin: 0 auto;

  }
  .logo{
    height: 100%;
    display: table;
    float:left;
  }
  
  .logo h1{
    color:white;
    height: 100%;
    display:table-cell;
    vertical-align: middle;
    font-family: 'Montserrat';
    font-size: 32px;

  }

  .navigation{
    float:right;
    height: 100%;
  }
  .navigation a{ 
    height: 100%;
    display: table;
    float: left;
    padding: 0px 20px;
  }

  .navigation a:last-child{
    padding-right: 0;


  }
  .navigation a li {
    display: table-cell;
    vertical-align: middle;
    color: white;
    font-family: 'Montserrat';
    font-size: 16px;

    
  }
  a li:hover {
   transition: all 0.3s ease 0s;
   background-color: rgba(0, 136, 169, 0.8);
  }

  .disabled {
    pointer-events:none;
    opacity:0.6;       
  } 

  .MuiDialogContent-root ul li {
    background-color: rgb(239, 244, 245);
  }